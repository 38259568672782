@font-face {
  font-family: 'DINPro';
  font-style: normal;
  font-weight: 400;
  src: 
       url('/static/css/fonts/DINPro.woff2') format('woff2'); /* Super Modern Browsers */
}

@font-face {
  font-family: 'DINPro';
  font-style: normal;
  font-weight: 700;
  src: 
       url('/static/css/fonts/DINPro-Medium.woff2') format('woff2'); /* Super Modern Browsers */
}

@font-face {
  font-family: 'DINPro';
  font-style: italic;
  font-weight: 400;
  src:
       url('/static/css/fonts/DINPro-Italic.woff2') format('woff2'); /* Super Modern Browsers */
}

@font-face {
  font-family: 'DINPro';
  font-style: italic;
  font-weight: 700;
  src: 
       url('/static/css/fonts/DINPro-MediumItalic.woff2') format('woff2'); /* Super Modern Browsers */
}

// irodriguez@vbote.com: added new font.
@font-face {
  font-family: 'Protipo_pnp';
  font-style: normal;
  font-weight: 400;
  src: 
       url('/static/css/fonts/Protipo-Regular-pnp.woff2') format('woff2'); /* Super Modern Browsers */
}

@font-face {
  font-family: 'Protipo_pnp';
  font-style: normal;
  font-weight: 600;
  src: 
       url('/static/css/fonts/Protipo-Semibold-pnp.woff2') format('woff2'); /* Super Modern Browsers */
}

@font-face {
  font-family: 'Protipo_pnp';
  font-style: normal;
  font-weight: 700;
  src: 
       url('/static/css/fonts/Protipo-Bold-pnp.woff2') format('woff2'); /* Super Modern Browsers */
}

@font-face {
  font-family: 'Protipo_pnp';
  font-style: italic;
  font-weight: 400;
  src: 
       url('/static/css/fonts/Protipo-Italic-pnp.woff2') format('woff2'); /* Super Modern Browsers */
}

@font-face {
  font-family: 'Protipo_pnp';
  font-style: italic;
  font-weight: 600;
  src: 
       url('/static/css/fonts/Protipo-SemiboldItalic-pnp.woff2') format('woff2'); /* Super Modern Browsers */
}

@font-face {
  font-family: 'Protipo_pnp';
  font-style: italic;
  font-weight: 700;
  src: 
       url('/static/css/fonts/Protipo-BoldItalic-pnp.woff2') format('woff2'); /* Super Modern Browsers */
}